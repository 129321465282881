<div class="card question-route">
    <div class="filter-header">
        <div class="col-sm-12">
            <div class="input-group search-group">
                <div class="search-header">
                    <button color="warn" mat-raised-button class="new-question-button"
                            *jhiHasAnyAuthority="['QA_DELETE','QA_SHARE','QA_WRITE']"
                            (click)="openNewQuestion()">
                        <span jhiTranslate="vdrApp.question.home.createLabel">New question</span>
                    </button>

                    <form (ngSubmit)="search()">
                        <div class="search-input" fxLayout="row">
                            <input name="phrase" [(ngModel)]="questionSearchForm.phrase" type="text"
                                   class="form-control"
                                   placeholder="{{'vdrApp.question.phrase' | translate}}..."/>

                            <mat-divider [vertical]="true"></mat-divider>
                            <button style="margin-left:0px;"

                                    mat-icon-button
                                    type="submit">
                                <mat-icon>search</mat-icon>
                            </button>
                            <mat-divider [vertical]="true"></mat-divider>
                            <div fxLayout="column">
                                <button
                                    mat-icon-button
                                    type="button"
                                    (click)="clearFilters()"
                                    [disabled]="!questionSearchForm.isCriteria">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </div>
                            <mat-divider [vertical]="true"></mat-divider>
                            <div fxLayout="column">
                                <button
                                    mat-icon-button
                                    type="button"
                                    (click)="toggleSearchBox()">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                            </div>

                        </div>
                    </form>

                    <button color="warn" mat-raised-button class="new-question-button" style="margin-left: 14px"
                            *jhiHasAnyAuthority="['ADMIN', 'ADMIN_LIMITED']"
                            (click)="openCategories()">
                        <span jhiTranslate="vdrApp.question.filter.categories">Categories</span>
                    </button>

                    <button color="warn" mat-raised-button class="new-question-button" style="min-width: 70px;margin-left: 4px"
                            *jhiHasAnyAuthority="['ADMIN', 'ADMIN_LIMITED']"
                            (click)="export()"
                            matTooltip="{{'report.excel.button.tooltip' | translate}}"
                            [disabled]="loading">
                        <mat-icon  *ngIf="!loading">get_app</mat-icon>
                        <ng-container *ngIf="loading">
                            <mat-icon><mat-spinner diameter="20"></mat-spinner></mat-icon>
                        </ng-container>
                    </button>

                    <button color="warn" mat-raised-button class="new-question-button" style="min-width: 70px;margin-left: 4px"
                            (click)="loadPage(page+1)"
                            matTooltip="Załaduj kolejną stronę z pytaniami"
                            >
                        <i class="fa fa-refresh"></i>
                    </button>

                    <div style="margin-left: 10px;margin-top: 10px">
                        <span>{{ questionLimitInfo }}</span>
                    </div>

                </div>

            </div>


            <div class="dropdown-menu question-search-panel" *ngIf="isShowSearchBox">
                <div class="filter-item" fxLayout="column">

                    <div class="search-item-group" *jhiHasAnyAuthority="['ADMIN', 'ADMIN_LIMITED']">
                        <label jhiTranslate="vdrApp.question.filter.groups">Groups</label>
                        <mat-select placeholder="" [(ngModel)]="questionSearchForm.groups" multiple>
                            <mat-option *ngFor="let group of groups" [value]="group">{{ group.name }}</mat-option>
                        </mat-select>
                    </div>

                    <mat-form-field fxFill>
                        <input
                            name="createdFrom"
                            matInput
                            [matDatepicker]="createdFromPicker"
                            [(ngModel)]="questionSearchForm.fromDay"
                            placeholder="{{'document.filter.created-from' | translate}}"
                            disabled>
                        <mat-datepicker-toggle matSuffix [for]="createdFromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #createdFromPicker disabled="false"></mat-datepicker>
                    </mat-form-field>

                    <mat-divider></mat-divider>

                    <mat-form-field fxFill>
                        <input
                            name="createdTo"
                            matInput
                            [matDatepicker]="createdToPicker"
                            [(ngModel)]="questionSearchForm.untilDay"
                            placeholder="{{'document.filter.created-to' | translate}}"
                            disabled>
                        <mat-datepicker-toggle matSuffix [for]="createdToPicker"></mat-datepicker-toggle>
                        <mat-datepicker #createdToPicker disabled="false"></mat-datepicker>
                    </mat-form-field>

                    <mat-divider></mat-divider>

                    <div class="search-item-group">
                        <label jhiTranslate="vdrApp.question.filter.categories">Categories</label>
                        <mat-select placeholder="" [(ngModel)]="questionSearchForm.categories" multiple>
                            <mat-option *ngFor="let category of categories" [value]="category">{{ category.name }}</mat-option>
                        </mat-select>
                    </div>

                    <div class="search-item-group">
                        <label><span jhiTranslate="vdrApp.question.priority">Priority</span></label>
                        <mat-select placeholder="" [(ngModel)]="questionSearchForm.priorities" multiple>
                            <mat-option value="LOW">{{'vdrApp.QuestionPriority.LOW' | translate}}</mat-option>
                            <mat-option value="MEDIUM">{{'vdrApp.QuestionPriority.MEDIUM' | translate}}</mat-option>
                            <mat-option value="HIGH">{{'vdrApp.QuestionPriority.HIGH' | translate}}</mat-option>
                        </mat-select>
                    </div>

                    <div class="search-item-group">
                        <label><span jhiTranslate="vdrApp.question.author">Author</span></label>
                        <input [(ngModel)]="questionSearchForm.author"
                               class="form-control search-item"
                        >
                    </div>
                </div>

                <div class="question-search-button">
                    <button color="warn" mat-raised-button
                            (click)="search()">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="filter" *ngIf="questionSearch.isCriteria">
        <mat-chip-list  fxFlex="98%">
            <mat-chip *ngIf="questionSearch.groups" (removed)="clearField('groups')">
                <span jhiTranslate="vdrApp.question.filter.groups">Groups</span>
                <span>{{': ' + toString(questionSearch.groups)}}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip *ngIf="questionSearch.fromDay" (removed)="clearField('fromDay')">
                <span jhiTranslate="document.filter.created-from">Created from</span>
                <span>{{': ' + (questionSearch.fromDay | date: 'yyyy-MM-dd')}}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip *ngIf="questionSearch.untilDay" (removed)="clearField('untilDay')">
                <span jhiTranslate="document.filter.created-to">Created to</span>
                <span>{{': ' + (questionSearch.untilDay | date: 'yyyy-MM-dd')}}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip *ngIf="questionSearch.categories" (removed)="clearField('categories')">
                <span jhiTranslate="vdrApp.question.filter.categories">Categories</span>
                <span>{{': ' + toString(questionSearch.categories)}}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip *ngIf="questionSearch.priorities" (removed)="clearField('priorities')">
                <span jhiTranslate="vdrApp.question.priority">Priority</span>
                <span>{{': ' + toStringPriorities()}}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip *ngIf="questionSearch.author" (removed)="clearField('author')">
                <span jhiTranslate="vdrApp.question.author">Author</span>
                <span>{{': ' + questionSearch.author}}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>

        </mat-chip-list>
    </div>

    <div class="questions-container">
        <div class="question-card">
            <mat-tab-group [selectedIndex]="tabs.indexOf(tabStatus)"
                           (selectedTabChange)="changeTab($event)">

                <mat-tab *ngFor="let tab of tabs; let index = index"
                         [label]="'vdrApp.QuestionStatus.'+tab | translate | uppercase">

                    <div class="button-panel question-sort d-flex justify-content-between"
                         jhiSort [(predicate)]="predicate" [(ascending)]="reverse" [callback]="transition.bind(this)">
                        <div jhiSortBy="businessId">
                            <span>#</span>
                            <i class="fa fa-sort-amount"></i>
                        </div>
                        <div jhiSortBy="createdDate">
                            <span jhiTranslate="vdrApp.question.createdDate">Creation Time</span>
                            <i class="fa fa-sort-amount"></i>
                        </div>
                    </div>


                    <div class="table-responsive question-list" *ngIf="questions">
                        <table class="table">
                            <tbody infinite-scroll class="scroll"
                                   (scrolled)="loadPage(page+1)"
                                   [infiniteScrollDistance]="1"
                                   [infiniteScrollThrottle]="10"
                                   [scrollWindow]="false"
                                   [infiniteScrollDisabled]="infiniteDisabled()"
                                   >
                            <tr [class.selectedrow]="isSelected(questionListElement)" class="question-row"
                                *ngFor="let questionListElement of questions ;trackBy: trackId"
                                (click)="chooseQuestion(questionListElement)">
                                <td class="firstColumn">
                                    <i *ngIf="!questionListElement.avatarBytes" class="fa fa-fw fa-user-circle"
                                       style="font-size:70px;opacity: 0.5"></i>
                                    <div *ngIf="questionListElement.avatarBytes" class="image-cropper"
                                         style="   width:  70px;    height:  70px;margin:inherit; margin-left:14px;">
                                        <img class="profile-pic avatar" style="width: 70px;"
                                             [src]="'data:image/jpeg;base64,' + questionListElement.avatarBytes"
                                             alt="file image"/>
                                    </div>


                                </td>
                                <td class="secondColumn">
                                    <div class="priority">
                                        <i class="fa fa-exclamation" *ngIf="questionListElement.isHighPriority()"></i>
                                    </div>
                                </td>
                                <td>
                                    <div class="question-extract">
                                        <div class="author">{{questionListElement.authorName}}</div>
                                        <div class="title">
                                            <a [routerLink]="['../' + questionListElement.id]" replaceUrl="true"
                                               queryParamsHandling="merge">
                                                {{questionListElement.title}}
                                            </a>
                                        </div>
                                    </div>
                                    <div class="documentName">
                                        {{questionListElement.documentName}}
                                    </div>
                                </td>
                                <td class="fourthColumn">
                                    <div class="status-column">
                                        <div
                                            class="create-date">{{questionListElement.createdDate| date: 'yyyy-MM-dd HH:mm'}}</div>
                                        <div class="status"  *ngIf="questionListElement.active">
                                            <span class="cell-button  badge badge-info"
                                                  [ngClass]="{'resolved' : questionListElement.isResolved(), 'pending': questionListElement.status === 'PENDING', 'new': questionListElement.status === 'WAITING_FOR_ANSWER'}">
                                                <span
                                                    jhiTranslate="{{'vdrApp.QuestionStatus.' + questionListElement.status}}">{{questionListElement.status}}</span>
                                                <i *ngIf="questionListElement.isResolved()"
                                                   class="resolved-icon fa fa-check-circle" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <span *ngIf="!questionListElement.active"  class="cell-button badge badge-info canceled">
                                                Usunięte
                                                <i class="fa fa-remove"  aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <div class="answer-number">
                                            {{questionListElement.answersCount}}
                                            <i class="fa fa-comment"></i>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>

        <ng-container
            [ngTemplateOutlet]="answerCard"
            [ngTemplateOutletContext]="{question:question}">
        </ng-container>
    </div>

</div>


<ng-template #answerCard let-question="question">

    <div class="answer-card" [@question]="question">

        <div *ngIf="question" class="main-answer-panel">
            <div class="panel question-right-panel">
                <div class="answer-info">
                    {{question?.authorName}}
                    <div class="status-column">
                        <div class="create-date">{{question?.createdDate | date: 'yyyy-MM-dd HH:mm'}}</div>
                        <div class="status" *ngIf="question.active">
                                <span class="cell-button  badge badge-info"
                                      [ngClass]="{'resolved' : question.isResolved(), 'pending': question.status === 'PENDING', 'new': question.status === 'WAITING_FOR_ANSWER'}">
                                    <span
                                        jhiTranslate="{{'vdrApp.QuestionStatus.' + question.status}}">{{question.status}}</span>
                                    <i *ngIf="question.isResolved()" class="resolved-icon fa fa-check-circle"
                                       aria-hidden="true"></i>
                                </span>
                        </div>
                        <div>
                            <span *ngIf="!question.active" class="cell-button badge badge-info canceled">
                                Usunięte
                                <i class="fa fa-remove"  aria-hidden="true"></i>
                            </span>
                        </div>
                        <div>
                            <span class="answer-number">{{question?.answersCount}}</span>
                            <i class="fa fa-comment"></i>
                        </div>
                        <div>
                            <i class="priority fa fa-exclamation" *ngIf="question.isHighPriority()"></i>
                            <mat-form-field *ngIf="canChangePriority(); else justPriority">
                                <mat-select [(ngModel)]="question.priority"
                                            (selectionChange)="changePriority($event)"
                                            matTooltip="{{ 'vdrApp.question.priority' | translate }}"
                                            >
                                    <mat-option
                                        value="LOW">{{'vdrApp.QuestionPriority.LOW' | translate}}</mat-option>
                                    <mat-option
                                        value="MEDIUM">{{'vdrApp.QuestionPriority.MEDIUM' | translate}}</mat-option>
                                    <mat-option value="HIGH">
                                        {{'vdrApp.QuestionPriority.HIGH' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <ng-template #justPriority>
                                    <span
                                        class="priority-label">{{'vdrApp.QuestionPriority.' + question.priority | translate}}</span>
                            </ng-template>
                        </div>

                        <div>
                            <div  *ngIf="canChangePriority(); else justCategories">
                                <span *ngIf="!question.categories" jhiTranslate="vdrApp.question.category">Category</span>
                                <mat-select [(ngModel)]="selectedCategorieIDs"
                                            multiple
                                            (closed)="closeCategories()"
                                            matTooltip="{{ 'vdrApp.question.category' | translate }}"
                                >
                                    <mat-option *ngFor="let cat of categories" [value]="cat.id">{{cat.name}}</mat-option>
                                </mat-select>
                            </div>

                            <ng-template #justCategories>
                                <span>{{ getNameOfCategories() }}</span>
                            </ng-template>
                        </div>

                    </div>
                </div>
                <div class="title">
                    {{question?.title}}
                </div>
                <div class="documentName" *ngIf="question.document">
                    <span jhiTranslate="vdrApp.question.selectedDocumentLabel">Dotyczy:</span>
                    <a *ngIf="question.document.pdfSupport"
                       (click)="pdfViewer(question.document.id, [])">{{question.document.name }}</a>
                    <a *ngIf="!question.document.pdfSupport && question.document.effectivePermissions.includes('DOWNLOAD_ORIGINAL')"
                       (click)="downloadFile(question.document.id)"> {{question.document.name}}</a>
                    <span
                        *ngIf="!question.document.pdfSupport && !question.document.effectivePermissions.includes('DOWNLOAD_ORIGINAL')">
                                    {{question.document.name}}
                    </span>
                </div>

                <div class="question-detail" *ngIf="question">
                    <div class="question-detail-to">
                            <span class="question-recipient-label"
                                  jhiTranslate="vdrApp.question.recipient.to">DO:</span>
                        <span *ngFor="let recipient of question.getRecipientsTO() ;trackBy: trackId">
                        <span class="question-recipient">
                            {{recipient.recipientName}}
                        </span>
                    </span>
                    </div>
                    <div class="question-detail-to" *ngIf="question.getRecipientsCC().length > 0">
                        <div>
                            <span class="question-recipient-label"
                                  jhiTranslate="vdrApp.question.recipient.cc">DW:</span>
                            <span class="question-recipient"
                                  *ngFor="let recipient of question.getRecipientsCC() ;trackBy: trackId">
                                <span class="question-recipient">
                                    {{recipient.recipientName}}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="question-detail-content">
                        <div class="ql-editor" [innerHTML]="question.content | safeHtml"></div>
                    </div>

                    <div class="attachments">
                        <ul class="fa-ul" *ngIf="question.attachments">
                            <li *ngFor="let attachment of question.attachments">
                                <span class="fa-li"><i class="fa fa-paperclip"></i></span>
                                <a *ngIf="attachment.pdfSupport"
                                   (click)="pdfViewer(attachment.id, [])">{{attachment.name }}</a>
                                <a *ngIf="!attachment.pdfSupport && attachment.effectivePermissions.includes('DOWNLOAD_ORIGINAL')"
                                   (click)="downloadFile(attachment.id)"> {{attachment.name}}</a>
                                <span
                                    *ngIf="!attachment.pdfSupport && !attachment.effectivePermissions.includes('DOWNLOAD_ORIGINAL')">
                                    {{attachment.name}}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="answer-button-panel1" *ngIf="!question.active">
                        <div class="reply-button-panel">
                            <button
                                *ngIf="question | checkQuestionDeletePipe" class="button-square"
                                mat-button data-dismiss="modal"
                                matTooltip="{{ 'vdrApp.question.restoreQuestion' | translate }}"
                                (click)="restoreQuestion()">
                                <i class="fa fa-undo"></i>
                            </button>
                        </div>
                    </div>
                    <div class="answer-button-panel1" *ngIf="question.active">
                        <div class="reply-button-panel">

                            <button
                                *ngIf="question | checkQuestionDeletePipe" class="button-square"
                                mat-button data-dismiss="modal"
                                matTooltip="{{ 'vdrApp.question.deleteQuestion' | translate }}"
                                (click)="deleteQuestion()">
                                <i class="fa fa-trash"></i>
                            </button>
                            <button class="button-square"
                                    matTooltip="{{ 'vdrApp.question.markAsResolved' | translate }}"
                                    *ngIf="questionCanBeResolved(question)" mat-button (click)="markAsResolved()">
                                <i class="fa fa-check-circle"></i>
                            </button>
                        </div>
                        <div class="reply-button-panel">
                            <button type="button"
                                    mat-button class="button-square"

                                    matTooltip="{{ 'vdrApp.question.newAnswer' | translate }}"
                                    *jhiHasAnyAuthority="['QA_DELETE','QA_SHARE','QA_WRITE']"
                                    data-dismiss="modal"
                                    (click)="showNewAnswerForm(question)"
                                    [disabled]="!isNewAnswerButtonVisible()"
                            >
                                <i class="fa fa-reply"></i>
                            </button>
                            <button type="button" class="button-square"
                                    mat-button
                                    matTooltip="{{ 'vdrApp.question.share' | translate }}"
                                    *jhiHasAnyAuthority="['QA_SHARE']"
                                    data-dismiss="modal"
                                    (click)="showNewForwardForm(question)"
                                    [disabled]="!isNewAnswerButtonVisible()"
                            >
                                <i class="fa fa-reply-all fa-flip-horizontal"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-2"></div>

            <div *ngIf="answers.length > -1" class="answer-list">
                <h5 class="ml-2 mt-2" jhiTranslate="vdrApp.question.answers">Odpowiedzi</h5>
                <div class="table-responsive">
                    <div fadeInAnimation *ngFor="let answersListElement of answers ;trackBy: trackId"
                         class="answer-detail  panel">
                        <div class="author-row">
                            <div>
                                <span class="question-recipient">{{answersListElement.authorName}}</span>
                            </div>
                            <div class="create-date">{{answersListElement.createdDate| date:'yyyy-MM-dd HH:mm'}}</div>
                        </div>
                        <div class="recipients-row">
                            <div>
                                        <span class="question-recipient-label"
                                              jhiTranslate="vdrApp.question.recipient.to">DO:</span>
                                <span class="question-recipient"
                                      *ngFor="let recipient of answersListElement?.recipients ;trackBy: trackId">
                                    {{recipient.recipientName}}
                                </span>
                            </div>
                        </div>

                        <div class="answer-box">
                            <div class="ql-editor" [innerHTML]="answersListElement.content | safeHtml"></div>
                        </div>

                        <div class="answer-box forward-box" *ngIf="answersListElement.forward">
                            {{'vdrApp.question.forward' | translate}}
                        </div>


                        <div class="actions-panel">

                            <button
                                mat-button class="button-square"
                                data-dismiss="modal"
                                matTooltip="{{ 'vdrApp.question.deleteAnswer' | translate }}"
                                (click)="deleteAnswer(answersListElement)" *ngIf="answersListElement | checkQuestionDeletePipe">
                                <i class="fa fa-trash"></i>
                            </button>
                            <div class="reply-button-panel">

                                <button
                                    mat-button
                                    class="button-square"
                                    matTooltip="{{ 'vdrApp.question.newAnswer' | translate }}"
                                    *jhiHasAnyAuthority="['QA_DELETE','QA_SHARE','QA_WRITE']"
                                    data-dismiss="modal"
                                    (click)="showNewAnswerForm(answersListElement)"
                                    [disabled]="!isNewAnswerButtonVisible()"
                                >
                                    <i class="fa fa-reply"></i>
                                </button>
                                <button
                                    mat-button
                                    class="button-square"
                                    matTooltip="{{ 'vdrApp.question.share' | translate }}"
                                    *jhiHasAnyAuthority="['QA_SHARE']"
                                    data-dismiss="modal"
                                    (click)="showNewForwardForm(answersListElement)"
                                    [disabled]="!isNewAnswerButtonVisible()"
                                >
                                    <i class="fa fa-reply-all fa-flip-horizontal"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!question" class="no-message-info"
                     jhiTranslate="vdrApp.question.noMessageSelected">No message selected</div>
            </div>
            <div #newAnswerForm>
                <div *ngIf="isNewAnswerFormVisible" class="new-answer">
                    <form name="newAnswerForm" (ngSubmit)="sendAnswer()" role="form" #newAnswerForm="ngForm">
                        <div class="answer-detail" [ngClass]="forwardMode? 'forward': '' ">
                            <div class="author-row">
                                <div>
                                            <span *ngIf="!forwardMode" class="question-recipient"
                                                  jhiTranslate="vdrApp.question.newAnswer">Udzielasz odpowiedzi</span>
                                    <span *ngIf="forwardMode" class="question-recipient"
                                          jhiTranslate="vdrApp.question.share">Udostępniasz pytanie</span>
                                </div>
                            </div>
                            <div class="recipients-row">
                                        <span class="question-recipient-label"
                                              jhiTranslate="vdrApp.question.recipient.to">DO:</span>

                                <p-autoComplete [(ngModel)]="newAnswer.recipients"
                                                name="multiple"
                                                placeholder="{{ 'vdrApp.question.recipients' | translate }}"
                                                field="recipientName"
                                                required
                                                [suggestions]="recipients"
                                                (completeMethod)="suggestRecipients($event)"
                                                [minLength]="0"
                                                [multiple]="true"
                                                [forceSelection]="true"
                                                [disabled]="cannotShareQuestion()"
                                                [dropdown]="true"
                                                [showTransitionOptions]="'500ms'"
                                                [hideTransitionOptions]="'200ms'"
                                                >
                                    <ng-template let-recipient pTemplate="item">
                                        <div class="ui-helper-clearfix template-border">
                                            <div *ngIf="recipient.groupId" class="group-recipient">
                                                <i class="fa fa-users"></i>
                                                {{recipient.recipientName}}
                                            </div>
                                            <div *ngIf="recipient.userId" class="user-recipient">
                                                <i class="fa fa-user"></i>
                                                {{recipient.recipientName}}
                                                <small>(<i class="fa fa-users"></i>&nbsp;{{recipient.groupName}})
                                                </small>
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-autoComplete>

                            </div>
                            <div *ngIf="!forwardMode">
                                 <p-editor class="form-control new-answer-content" name="answer-content" id="answer-content"
                                          placeholder="{{ 'vdrApp.question.content' | translate }}" maxlength="10000"
                                          [(ngModel)]="newAnswer.content" [style]="{'height':'250px'}"
                                          required (onTextChange)="checkAnswerContent($event)">
                                    <p-header>
                                        <span class="ql-formats">
                                            <select class="ql-size">
                                              <option value="small">Petit</option>
                                              <option selected>Normalne</option>
                                              <option value="large">Sous-titre</option>
                                              <option value="huge">Titre</option>
                                            </select>
                                          </span>
                                                        <span class="ql-formats">
                                            <button class="ql-bold" aria-label="Bold"></button>
                                            <button class="ql-italic" aria-label="Italic"></button>
                                            <button class="ql-underline" aria-label="Underline"></button>
                                            <button class="ql-strike" aria-label="Strike"></button>
                                        </span>
                                                        <span class="ql-formats">
                                            <button class="ql-list" value="ordered"></button>
                                            <button class="ql-list" value="bullet"></button>
                                            <select class="ql-align" >
                                                <option selected></option>
                                                <option value="center" label="Center"></option>
                                                <option value="right" label="Right"></option>
                                                <option value="justify" label="Justify"></option>
                                            </select>
                                        </span>
                                                        <span class="ql-formats">
                                            <select class="ql-color"></select>
                                            <select class="ql-background"></select>
                                        </span>
                                    </p-header>
                                </p-editor>
                            </div>
                            <div style="padding-top: 5px" *ngIf="!forwardMode">
                                <span style="color: var(--prime-color);float: right; margin-right: 10px">{{answerContentLength}}/9948</span>
                                <span class="form-text text-danger" style="margin-left: 10px" *ngIf="answerContentLength < 1">Minimalna ilość znaków to 1</span>
                                <span class="form-text text-danger" style="margin-left: 10px" *ngIf="answerContentLength > 9948">Maksymalna ilość znaków to 9948</span>
                            </div>
                            <div class="actions-panel">
                                <button
                                    matTooltip="{{ 'vdrApp.question.deleteAnswer' | translate }}"
                                    mat-button (click)="hideAnswer()">
                                    <i class="fa fa-trash"></i>
                                </button>
                                <button mat-raised-button color="warn"
                                        [disabled]="newAnswerForm.form.invalid || answerContentLength == 0 || answerContentLength > 9948"
                                        type="submit">
                                            <span *ngIf="!forwardMode"
                                                  jhiTranslate2="vdrApp.question.newAnswer">Wyślij</span>
                                    <span *ngIf="forwardMode" jhiTranslate="vdrApp.question.share">Wyślij</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>


